.base {
  @mixin icon;
}

._orange {
  color: var(--orange);
}

._nomargin {
  margin: 0;
}
