@define-mixin icon {
  --icon-size: 1em;
  margin-right: 8px;
  display: inline-flex;
  vertical-align: text-top;
  text-align: center;
  align-items: center;

  svg {
    height: var(--icon-size);
    width: var(--icon-size);
  }

  > span {
    font-style: normal;
  }
}
